import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
    background-color: ${theme.colors.white};
    overflow: hidden;
  `
);

export const Header = styled.div(
  ({ theme }) => css`
    padding: 16px;
    border-bottom: 1px solid ${theme.colors.greyShade100};
  `
);
export const InputWrapper = styled.div`
  max-width: 300px;
`;

export const Table = styled.div`
  display: flex;
  min-height: 410px;
`;
export const LeftSide = styled.div(
  ({ theme }) => css`
    min-width: 395px;
    border-right: 1px solid ${theme.colors.greyShade50};
  `
);
export const SideTableTitleWrapper = styled.div<{
  isGrey?: boolean;
  hasHover?: boolean;
  hasRedirect?: boolean;
}>(
  ({ theme, isGrey, hasHover, hasRedirect }) => css`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.greyShade50};
    ${isGrey &&
    css`
      background-color: ${theme.colors.grey50};
    `}
    ${hasHover &&
    css`
      background-color: ${theme.colors.grey50};
    `}
   ${hasRedirect &&
    css`
      cursor: pointer;
    `}
  `
);
export const ColorBox = styled.div<{
  color?: string;
  size?: "small" | "medium";
}>(
  ({ color, size = "medium" }) => css`
    width: 16px;
    height: 16px;
    margin: ${size === "small" ? "16px 24px" : "20px 24px"};
    border-radius: 4px;
    ${color &&
    css`
      background-color: ${color};
    `}
  `
);

export const TableHeaderItem = styled.span<{
  isBold?: boolean;
  isListItem?: boolean;
}>(
  ({ theme, isListItem, isBold = false }) => css`
    color: ${isBold ? theme.colors.textPrimary : theme.colors.textSecondary};
    font-size: 14px;
    line-height: 16px;
    padding: ${isListItem ? "24px 16px" : "16px"};
    width: 330px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);
export const RightSide = styled.div(
  ({ theme }) => css`
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.greyShade50};
  `
);

export const TableHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const TableHeaderContainer = styled.div(
  ({ theme }) => css`
    padding: 16px 24px;
    min-width: 182px;
    text-align: end;
    background-color: ${theme.colors.greyShade100};
    color: ${theme.colors.textSecondary};
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.greyShade50};
  `
);

export const TableList = styled.div<{ hasHover: boolean }>(
  ({ theme, hasHover }) => css`
    display: flex;
    min-width: 100%;
    width: max-content;
    border-bottom: 1px solid ${theme.colors.greyShade50};
    ${hasHover &&
    css`
      background-color: ${theme.colors.greyShade50};
    `}
  `
);

export const TableListItem = styled.div<{ isBold?: boolean }>(
  ({ theme, isBold }) => css`
    padding: 24px;
    color: ${theme.colors.textPrimary};
    font-size: 14px;

    line-height: 16px;
    min-width: 182px;
    width: 100%;
    text-align: end;
    ${isBold &&
    css`
      font-weight: 700;
    `}
  `
);

export const NoDataWrapper = styled.div`
  width: 100%;
  height: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const Content = styled.div`
  width: 310px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const NoDataTitle = styled.h3(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 13px;
    line-height: normal;
    padding: 0 50px;
  `
);

export const NoDataSubTitle = styled.h6(
  ({ theme }) => css`
    color: ${theme.colors.textSecondary};
    font-size: 1px;
    line-height: normal;
  `
);
