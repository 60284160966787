import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { chartColors } from "../../../../constants/color";
import { getUniqueLabels } from "../../../../helpers/dashboardHelper";
import { FinancialRecord } from "../../../../store/dashboard/dashboard.types";

import {
  ColorBox,
  Content,
  DropDownList,
  DropDownWrapper,
  Item,
  ItemWrapper,
  ListItem,
  ListItemWrapper,
  Wrapper,
} from "./BarChartLabels.style";

interface BarChartLabelsProps {
  data: Array<FinancialRecord>;
}

const BarChartLabels = ({ data }: BarChartLabelsProps) => {
  const { t } = useTranslation();
  const listLabels = getUniqueLabels(data);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [showList, setShowList] = useState<Array<string>>([]);
  const [dropDownList, setDropDownList] = useState<Array<string>>([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (wrapperRef.current) {
      const calculateWidths = () => {
        if (wrapperRef.current) {
          const wrapperWidth = wrapperRef.current.offsetWidth;

          const listItemWidth = 150;
          const calculatedItemsPerRow = Math.floor(
            wrapperWidth / listItemWidth
          );

          if (listLabels.length < calculatedItemsPerRow) {
            setDropDownList([]);
            setShowList(listLabels);
          } else {
            setShowList(listLabels.slice(0, calculatedItemsPerRow - 1));
            setDropDownList(listLabels.slice(calculatedItemsPerRow - 1));
          }
        }
      };

      const wrapperElement = wrapperRef.current;
      if (!wrapperElement) return;
      const resizeObserver = new ResizeObserver(() => {
        calculateWidths();
      });
      resizeObserver.observe(wrapperElement);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [listLabels]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  return (
    <Wrapper ref={wrapperRef}>
      {showList.map((itemName, index) => (
        <ListItemWrapper key={index}>
          <ColorBox color={chartColors[index]} />
          <ListItem>{itemName}</ListItem>
        </ListItemWrapper>
      ))}
      {Boolean(dropDownList.length) && (
        <DropDownWrapper ref={dropdownRef}>
          <Content onClick={() => setIsOpen((prev) => !prev)}>
            +{dropDownList.length} {t("BarChartLabels.ItemsTitle")}
          </Content>
          {isOpen && (
            <DropDownList>
              {dropDownList.map((item, index) => (
                <ItemWrapper key={index}>
                  <ColorBox color={chartColors[index]} />
                  <Item>{item}</Item>
                </ItemWrapper>
              ))}
            </DropDownList>
          )}
        </DropDownWrapper>
      )}
    </Wrapper>
  );
};

export default BarChartLabels;
