import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { ChartGroupTypes, PeriodType } from "../../enums/enum";
import { LoaderType } from "../type";

import {
  generateCashFlowGroupedChartDataForGroups,
  generateCashFlowOutcome,
  generateProfitLoseGroupedChartDataForGroups,
  generateProfitLossNetIncome,
  generateProfitLossNetIncomePercentage,
} from "../../helpers/dashboardHelper";
import {
  CashFlow,
  CashFlowDirectionType,
  FinancialGroupsResponse,
  Filter,
  FrontSideFilter,
  MarketData,
  ProfitLostData,
  ReportalReportItem,
  FinancialRecord,
  FinancialInDetailResponse,
} from "./dashboard.types";

import {
  getCashFlowInfo,
  getExpensesGroupById,
  getExpensesGroups,
  getExpensesInDetail,
  getMarketAllCompanyInfo,
  getMarketCompanyInfo,
  getProfitLossInfo,
  getReportalReport,
  getRevenueGroupById,
  getRevenueGroups,
  getRevenueInDetail,
} from "./dashboardAction";
import moment from "moment";

export type DashboardState = {
  hasError: boolean;
  loading: LoaderType;
  chartLoading: { groups: LoaderType; groupDetail: LoaderType };
  chartData: FinancialGroupsResponse;
  groupsOption: Array<{ id: string; name: string }>;

  // old version
  profitLossLastDateUpdate: string;
  profitLoss: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    netIncome: {
      data: Array<any>;
      labels: Array<string>;
    };
    netIncomePercentage: {
      data: Array<any>;
      labels: Array<string>;
    };
  };
  cashFlowInfoLastDateUpdate: string;
  cashFlowInfo: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    cashIn: {
      data: Array<any>;
      labels: Array<string>;
    };
  };
  reportalData: {
    financeStanding: Array<ReportalReportItem>;
    workOutcome: Array<ReportalReportItem>;
  };
  frontSideFilter: FrontSideFilter;
  filter: Filter;
  market: MarketData;
  marketWorkOutcomeCompanyInfo: Array<{
    idCode: number;
    orgNameInReport: string;
  }>;
};

const initialState: DashboardState = {
  hasError: false,
  loading: "idle",
  chartLoading: { groups: "idle", groupDetail: "idle" },
  chartData: { data: [], lastUpdateTime: "" },
  groupsOption: [],
  // old version
  profitLossLastDateUpdate: "",
  profitLoss: {
    groupedChartData: { data: [], labels: [] },
    netIncome: { data: [], labels: [] },
    netIncomePercentage: { data: [], labels: [] },
  },
  cashFlowInfoLastDateUpdate: "",
  cashFlowInfo: {
    groupedChartData: { data: [], labels: [] },
    cashIn: { data: [], labels: [] },
  },
  // todo need remove
  reportalData: { financeStanding: [], workOutcome: [] },
  market: {
    identificationCode: null,
    name: null,
    companyCategoryId: null,
    companyFormId: null,
    data: undefined,
  },
  frontSideFilter: {
    group: ChartGroupTypes.Grouped,
    lines: [],
    branches: [],
    groupNames: [],
  },
  filter: {
    periodType: PeriodType.Month,
    fromDate: moment().subtract(1, "year").format(),
    toDate: moment().format(),
  },

  marketWorkOutcomeCompanyInfo: [],
};

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    updateFrontSideFilter: (
      state,
      { payload }: { payload: FrontSideFilter }
    ) => {
      state.frontSideFilter = payload;
    },
    resetGroupeName: (state) => {
      state.frontSideFilter.groupNames = [];
    },
    resetLineAndBranch: (state) => {
      state.frontSideFilter.lines = [];
      state.frontSideFilter.branches = [];
    },
    updateFilter: (state, { payload }: { payload: Filter }) => {
      state.filter = payload;
    },

    generateRevenueChartData: (state) => {},
    generateExpensesChartData: (state) => {},
  },
  extraReducers: (builder: ActionReducerMapBuilder<DashboardState>) => {
    builder
      .addCase(getRevenueGroups.pending, (state) => {
        state.chartLoading.groups = "pending";
      })
      .addCase(
        getRevenueGroups.fulfilled,
        (state, { payload }: { payload: FinancialGroupsResponse }) => {
          state.chartLoading.groups = "succeeded";
          state.groupsOption = payload.filters || [];
          state.chartData = payload;
        }
      )
      .addCase(getRevenueGroups.rejected, (state) => {
        state.chartLoading.groups = "failed";
        state.groupsOption = [];
        state.chartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      });
    // getRevenueGroupById
    builder
      .addCase(getRevenueGroupById.pending, (state) => {
        state.chartLoading.groups = "pending";
      })
      .addCase(
        getRevenueGroupById.fulfilled,
        (state, { payload }: { payload: FinancialGroupsResponse }) => {
          state.chartLoading.groups = "succeeded";
          state.groupsOption = payload.filters || [];
          state.chartData = payload;
        }
      )
      .addCase(getRevenueGroupById.rejected, (state) => {
        state.chartLoading.groups = "failed";
        state.groupsOption = [];
        state.chartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      });
    // getRevenueInDetail
    builder
      .addCase(getRevenueInDetail.pending, (state) => {
        state.chartLoading.groups = "pending";
      })
      .addCase(
        getRevenueInDetail.fulfilled,
        (state, { payload }: { payload: FinancialInDetailResponse }) => {
          state.chartLoading.groups = "succeeded";
          const data: Array<FinancialRecord> = payload.data.map(
            (item, index) => ({
              id: item.accountId,
              name: item.accountName,
              ordering: index,
              period: item.period,
              sumAmount: item.total,
            })
          );

          state.chartData = { data, lastUpdateTime: payload.lastUpdateTime };
        }
      )
      .addCase(getRevenueInDetail.rejected, (state) => {
        state.chartLoading.groups = "failed";
        state.groupsOption = [];
        state.chartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })

      // getExpensesGroups
      .addCase(getExpensesGroups.pending, (state) => {
        state.chartLoading.groups = "pending";
      })
      .addCase(
        getExpensesGroups.fulfilled,
        (state, { payload }: { payload: FinancialGroupsResponse }) => {
          state.chartLoading.groups = "succeeded";
          state.groupsOption = payload.filters || [];
          state.chartData = payload;
        }
      )
      .addCase(getExpensesGroups.rejected, (state) => {
        state.chartLoading.groups = "failed";
        state.groupsOption = [];
        state.chartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })
      // getExpensesGroupById
      .addCase(getExpensesGroupById.pending, (state) => {
        state.chartLoading.groups = "pending";
      })
      .addCase(
        getExpensesGroupById.fulfilled,
        (state, { payload }: { payload: FinancialGroupsResponse }) => {
          state.chartLoading.groups = "succeeded";
          state.groupsOption = payload.filters || [];
          state.chartData = payload;
        }
      )
      .addCase(getExpensesGroupById.rejected, (state) => {
        state.chartLoading.groups = "failed";
        state.groupsOption = [];
        state.chartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })

      // getExpensesInDetail
      .addCase(getExpensesInDetail.pending, (state) => {
        state.chartLoading.groups = "pending";
      })
      .addCase(
        getExpensesInDetail.fulfilled,
        (state, { payload }: { payload: FinancialInDetailResponse }) => {
          state.chartLoading.groups = "succeeded";
          const data: Array<FinancialRecord> = payload.data.map(
            (item, index) => ({
              id: item.accountId,
              name: item.accountName,
              ordering: index,
              period: item.period,
              sumAmount: item.total,
            })
          );

          state.chartData = { data, lastUpdateTime: payload.lastUpdateTime };
        }
      )
      .addCase(getExpensesInDetail.rejected, (state) => {
        state.chartLoading.groups = "failed";
        state.groupsOption = [];
        state.chartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })

      // getProfitLossInfo
      .addCase(getProfitLossInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getProfitLossInfo.fulfilled, (state, { payload }) => {
        // grouped
        state.profitLossLastDateUpdate = payload.lastUpdateTime;
        let groupKeys: Array<string> = [];
        let hasCashIn = false;
        let hasCashOut = false;

        payload.data.forEach((element) => {
          hasCashIn ||= element.totalProfit !== 0;
          hasCashOut ||= element.totalExpense !== 0;
        });

        if (hasCashIn || hasCashOut) {
          groupKeys = ["Total Income", "Total Expense"];
        }

        if (groupKeys.length) {
          const data =
            payload.data.map((item) => ({
              [groupKeys[0]]: item.totalProfit,
              [groupKeys[1]]: item.totalExpense,
              period: item.period,
            })) || [];

          const groupedBarDataForGroup =
            generateProfitLoseGroupedChartDataForGroups(
              data as Array<ProfitLostData>,
              payload.periodType
            );

          const netIncome = generateProfitLossNetIncome(
            ["Income"],
            data as Array<ProfitLostData>,
            payload.periodType
          );

          const incomePercentage = generateProfitLossNetIncomePercentage(
            ["Income"],
            data as Array<ProfitLostData>,
            payload.periodType
          );

          state.profitLoss.netIncome = netIncome;
          state.profitLoss.netIncomePercentage = incomePercentage;

          state.profitLoss.groupedChartData = {
            data: Object.values(groupedBarDataForGroup),
            labels: groupKeys,
          };
        } else {
          state.profitLoss = {
            groupedChartData: { data: [], labels: [] },
            netIncome: { data: [], labels: [] },
            netIncomePercentage: { data: [], labels: [] },
          };
        }

        state.loading = "succeeded";
      })
      .addCase(getProfitLossInfo.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })

      // cashFlowInfo
      .addCase(getCashFlowInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCashFlowInfo.fulfilled, (state, { payload }) => {
        state.cashFlowInfoLastDateUpdate = payload.lastUpdateTime;

        let groupKeys: Array<string> = [];
        let hasCashIn = false;
        let hasCashOut = false;

        payload.data.forEach((element) => {
          hasCashIn =
            element.cashFlowDirection === CashFlowDirectionType.Received;
          hasCashOut = element.cashFlowDirection === CashFlowDirectionType.Paid;
        });

        if (hasCashIn || hasCashOut) {
          groupKeys = ["Cash In", "Cash Out"];
        }

        if (groupKeys.length) {
          const groupedBarDataForGroup =
            generateCashFlowGroupedChartDataForGroups(
              payload.data as Array<CashFlow>,
              payload.periodType
            );

          const cashIn = generateCashFlowOutcome(
            ["Net Cash Flow"],
            payload.data as Array<CashFlow>,
            payload.periodType
          );
          state.cashFlowInfo.cashIn = cashIn;
          state.cashFlowInfo.groupedChartData = {
            data: groupedBarDataForGroup,
            labels: groupKeys,
          };
        } else {
          state.cashFlowInfo = {
            groupedChartData: { data: [], labels: [] },
            cashIn: { data: [], labels: [] },
          };
        }

        state.loading = "succeeded";
      })
      .addCase(getCashFlowInfo.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      // getReportalReport
      .addCase(getReportalReport.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getReportalReport.fulfilled, (state, { payload }) => {
        state.loading = "succeeded";
        state.reportalData = payload;
      })

      .addCase(getReportalReport.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })

      // get reportal finance data

      // get Market info
      .addCase(getMarketCompanyInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMarketCompanyInfo.fulfilled, (state, { payload }) => {
        state.loading = "succeeded";
        state.market = payload;
      })
      .addCase(getMarketCompanyInfo.rejected, (state) => {
        state.loading = "failed";
      })
      // get Market company info
      .addCase(getMarketAllCompanyInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMarketAllCompanyInfo.fulfilled, (state, { payload }) => {
        state.marketWorkOutcomeCompanyInfo = payload;
        state.loading = "succeeded";
      })
      .addCase(getMarketAllCompanyInfo.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export const {
  updateFrontSideFilter,
  updateFilter,
  generateRevenueChartData,
  generateExpensesChartData,
  resetLineAndBranch,
  resetGroupeName,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
