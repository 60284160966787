import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BarChartTitleContainer,
  GroupBarChartContainer,
  GroupDetailChartWrapper,
  Header,
  LastUpdated,
  SkeletonWrapper,
  Title,
  TitleWrapper,
  TreeChartContainer,
  UpdateDateSkeleton,
  Wrapper,
} from "./Revenue.style";
import moment from "moment";
import BigCardSkeleton from "../../../../components/shared/dashboard/skeletons/bigCardSkeleton/BigCardSkeleton";
import {
  dashboardDateFormat,
  downloadFile,
  generateGridChartModes,
} from "../../../../helpers/helper";
import Filters from "../../../../components/shared/dashboard/filters/Filters";
import CardGroupsSkeleton from "../../../../components/shared/dashboard/skeletons/cardGroupsSkeleton/CardGroupsSkeleton";
import DashboardChartCard from "../../../../components/shared/card/Card";

import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  getRevenueGroups,
  getRevenueGroupsExcel,
} from "../../../../store/dashboard/dashboardAction";
import {
  getCompanyBranch,
  getCompanyBusinessLine,
} from "../../../../store/company/companyAction";
import {
  CardTypes,
  ChartMode,
  ChartType,
  PeriodType,
  RangeType,
} from "../../../../enums/enum";
import { getRangeCurrentYear } from "../../../../helpers/dateHelper";
import { FinancialGroupsRequest } from "../../../../store/dashboard/dashboard.types";
import GroupBarChart from "../../../../components/desktop/charts/groupBar/GroupBarChart";
import TreeChart from "../../../../components/desktop/charts/treeChart/TreeChart";
import BarChartLabels from "../../../../components/shared/dashboard/barChartLabels/BarChartLabels";

import TableChart from "../../../../components/desktop/charts/tableChart/TableChart";
import ChartTypeSwitcher from "../../../../components/shared/dashboard/chartTypeSwitcher/ChartTypeSwitcher";
import GridChart from "../../../../components/desktop/charts/gridChart/GridChart";
import {
  formatNumber,
  sumRevenueExpenses,
} from "../../../../helpers/dashboardHelper";

import { useNavigate } from "react-router-dom";
import { ActionType } from "../../../../store/type";
import { isMobile } from "react-device-detect";

const initialFilterData = {
  date: {
    type: RangeType.CurrentYear,
    range: getRangeCurrentYear(),
  },
  period: PeriodType.Month,
  groups: [],
  branches: [],
  businessLines: [],
};

const Revenue = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isShowClearAll, setIsShowClearAll] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState(
    initialFilterData.date
  );

  const [selectedPeriodType, setSelectedPeriodType] = useState(
    initialFilterData.period
  );
  const [selectedGroups, setSelectedGroups] = useState<Array<string>>(
    initialFilterData.groups
  );
  const [selectedBranches, setSelectedBranches] = useState<Array<string>>([]);
  const [selectedBusinessLine, setSelectedBusinessLine] = useState<
    Array<string>
  >(initialFilterData.businessLines);

  const [cardViewMode, setCardViewMode] = useState<ChartMode>(
    ChartMode.Stacked
  );

  const [selectedChartType, setSelectedChartType] = useState<ChartType>(
    ChartType.GROUP
  );
  const [viewMode, setViewMode] = useState<ChartMode>(ChartMode.Grid);

  const companyBusinessLine = useAppSelector(
    (state) => state.company.companyBusinessLine
  );

  const companyBranches = useAppSelector(
    (state) => state.company.companyBranch
  );

  const revenue = useAppSelector((state) => state.dashboard.chartData);

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const revenueGroupsOption = useAppSelector(
    (state) => state.dashboard.groupsOption
  );

  const loading = useAppSelector((state) => state.dashboard.chartLoading);

  const totalSum = useMemo(() => {
    return formatNumber(sumRevenueExpenses(revenue.data));
  }, [revenue.data]);

  useEffect(() => {
    const promises = [
      dispatch(getCompanyBusinessLine()),
      dispatch(getCompanyBranch()),
    ];
    Promise.all(promises).finally(() => {});
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    dispatch(
      getRevenueGroups({
        periodType: PeriodType.Month,
        fromDate: moment(initialFilterData.date.range[0]).format("YYYY-MM-DD"),
        toDate: moment(initialFilterData.date.range[1]).format("YYYY-MM-DD"),
        chartType: ChartType.GROUP,
      })
    );
  }, [dispatch, selectedCompany]);

  const handleClearAll = () => {
    dispatch(
      getRevenueGroups({
        periodType: selectedPeriodType,
        fromDate: moment(initialFilterData.date.range[0]).format("YYYY-MM-DD"),
        toDate: moment(initialFilterData.date.range[1]).format("YYYY-MM-DD"),
        chartType: selectedChartType,
      })
    );
  };

  const handleFilter = ({
    businessLines,
    branches,
    groups,
    periodType,
    chartType,
    dateRange,
  }: {
    businessLines?: Array<string>;
    branches?: Array<string>;
    groups?: Array<string>;
    periodType?: PeriodType;
    chartType?: ChartType;
    dateRange?: Array<Date>;
  }) => {
    let data: FinancialGroupsRequest = {
      periodType: periodType ? periodType : selectedPeriodType,
      fromDate: dateRange?.length
        ? moment(dateRange[0]).format("YYYY-MM-DD")
        : moment(selectedDateRange.range[0]).format("YYYY-MM-DD"),
      toDate: dateRange?.length
        ? moment(dateRange[1]).format("YYYY-MM-DD")
        : moment(selectedDateRange.range[1]).format("YYYY-MM-DD"),
      chartType: chartType ? chartType : selectedChartType,
    };
    if (businessLines?.length || selectedBusinessLine.length) {
      data = {
        ...data,
        companyBusinessLineIds: businessLines?.length
          ? businessLines
          : selectedBusinessLine,
      };
    }
    if (branches?.length || selectedBranches.length) {
      data = {
        ...data,
        companyBranchIds: branches?.length ? branches : selectedBranches,
      };
    }
    if (groups?.length || selectedGroups) {
      data = {
        ...data,
        accountMappingGroupIds: groups?.length ? groups : selectedGroups,
      };
    }
    dispatch(getRevenueGroups(data));
  };

  const handleNavigationGroupDetail = (groupId: number | string) => {
    navigate(groupId.toString());
  };

  const handleDownload = () => {
    const data: FinancialGroupsRequest = {
      periodType: selectedPeriodType,
      fromDate: moment(selectedDateRange.range[0]).format("YYYY-MM-DD"),
      toDate: moment(selectedDateRange.range[1]).format("YYYY-MM-DD"),
      chartType: selectedChartType,
      companyBranchIds: selectedBranches,
      companyBusinessLineIds: selectedBusinessLine,
      accountMappingGroupIds: selectedGroups,
    };

    dispatch(getRevenueGroupsExcel(data))
      .then((action: ActionType) => {
        if (action.error) {
          console.error("Download failed:", action.error);
        } else if (action?.payload) {
          downloadFile(action?.payload);
        }
      })
      .catch((error) => {
        console.error("Unexpected error during download:", error);
      });
  };

  if (isMobile) return <div>under construction</div>;

  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <Title>{t("Revenue.Title")}</Title>
          {revenue.lastUpdateTime ? (
            <LastUpdated>
              {t("Revenue.DateUpdatedTitle")}:{" "}
              {dashboardDateFormat(revenue.lastUpdateTime)}
            </LastUpdated>
          ) : (
            <UpdateDateSkeleton />
          )}
        </TitleWrapper>

        <Filters
          isShowClearAll={isShowClearAll}
          groupOptions={revenueGroupsOption}
          selectedDateRange={selectedDateRange}
          selectedBranches={selectedBranches}
          selectedGroups={selectedGroups}
          selectedBusinessLine={selectedBusinessLine}
          isLoading={false}
          branchOptions={companyBranches.map((item) => ({
            id: item.id.toString(),
            name: item.name,
          }))}
          lineOptions={companyBusinessLine.map((item) => ({
            id: item.id.toString(),
            name: item.name,
          }))}
          handleDateRange={(dateData) => {
            setIsShowClearAll(true);
            setSelectedDateRange(dateData);
            handleFilter({ dateRange: dateData.range });
          }}
          handleBusinessLines={(businessLines) => {
            setSelectedBusinessLine(businessLines);
            setIsShowClearAll(true);
            handleFilter({ businessLines });
          }}
          handleBranches={(branches) => {
            setSelectedBranches(branches);
            setIsShowClearAll(true);
            handleFilter({ branches });
          }}
          handleGroups={(groups) => {
            setSelectedGroups(groups);
            setIsShowClearAll(true);
            handleFilter({ groups });
          }}
          handleClearAll={() => {
            setIsShowClearAll(false);
            setSelectedDateRange(initialFilterData.date);
            setSelectedBusinessLine(initialFilterData.businessLines);
            setSelectedBranches(initialFilterData.branches);
            setSelectedGroups(initialFilterData.groups);
            handleClearAll();
          }}
          handleDownload={handleDownload}
        />
      </Header>
      {loading.groups === "pending" ? (
        <SkeletonWrapper>
          <BigCardSkeleton />
          <CardGroupsSkeleton />
        </SkeletonWrapper>
      ) : (
        <>
          <DashboardChartCard
            selectedPeriodType={selectedPeriodType}
            selectedChartType={selectedChartType}
            selectedViewType={cardViewMode}
            type={CardTypes.LARGE}
            title={t("Revenue.DashboardCardTitle")}
            value={totalSum}
            hasBranch={Boolean(companyBranches.length)}
            hasLine={Boolean(companyBusinessLine.length)}
            handlePeriodType={(periodType) => {
              setSelectedPeriodType(periodType);
              handleFilter({ periodType });
            }}
            handleChartType={(chartType) => {
              setSelectedChartType(chartType);
              handleFilter({ chartType });
            }}
            handleViewMode={setCardViewMode}
          >
            {cardViewMode === ChartMode.Stacked && (
              <>
                <BarChartLabels data={revenue.data} />
                <GroupBarChartContainer>
                  <GroupBarChart
                    data={revenue.data}
                    periodType={selectedPeriodType}
                    stackId={"a"}
                  />
                </GroupBarChartContainer>
              </>
            )}
            {cardViewMode === ChartMode.Tree && (
              <TreeChartContainer>
                <TreeChart data={revenue.data} />
              </TreeChartContainer>
            )}
          </DashboardChartCard>
          <GroupDetailChartWrapper>
            <BarChartTitleContainer>
              <Title>{t("Revenue.GroupChartTitle")}</Title>
              <ChartTypeSwitcher
                viewOptions={generateGridChartModes()}
                selectedViewMode={viewMode}
                handleViewMode={setViewMode}
              />
            </BarChartTitleContainer>
            {viewMode === ChartMode.Grid && (
              <GridChart
                handleViewDetail={
                  selectedChartType === ChartType.GROUP
                    ? handleNavigationGroupDetail
                    : undefined
                }
                periodType={selectedPeriodType}
                data={revenue.data}
              />
            )}
            {viewMode === ChartMode.Table && (
              <TableChart
                handleClick={
                  selectedChartType === ChartType.GROUP
                    ? handleNavigationGroupDetail
                    : undefined
                }
                periodType={selectedPeriodType}
                data={revenue.data}
              />
            )}
          </GroupDetailChartWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default Revenue;
