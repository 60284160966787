import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BarChartTitleContainer,
  GroupDetailChartWrapper,
  Header,
  NavLink,
  SkeletonWrapper,
  Title,
  TitleWrapper,
  TreeChartContainer,
  Wrapper,
} from "./RevenueInDetail.style";
import moment from "moment";
import BigCardSkeleton from "../../../../components/shared/dashboard/skeletons/bigCardSkeleton/BigCardSkeleton";

import Filters from "../../../../components/shared/dashboard/filters/Filters";
import CardGroupsSkeleton from "../../../../components/shared/dashboard/skeletons/cardGroupsSkeleton/CardGroupsSkeleton";
import DashboardChartCard from "../../../../components/shared/card/Card";

import { useAppDispatch, useAppSelector } from "../../../../store";
import { getRevenueInDetail } from "../../../../store/dashboard/dashboardAction";
import {
  getCompanyBranch,
  getCompanyBusinessLine,
} from "../../../../store/company/companyAction";
import {
  CardTypes,
  ChartMode,
  ChartType,
  PeriodType,
  RangeType,
} from "../../../../enums/enum";
import { getRangeCurrentYear } from "../../../../helpers/dateHelper";

import TreeChart from "../../../../components/desktop/charts/treeChart/TreeChart";

import TableChart from "../../../../components/desktop/charts/tableChart/TableChart";

import {
  formatNumber,
  sumRevenueExpenses,
} from "../../../../helpers/dashboardHelper";
import { Link, useParams } from "react-router-dom";
import Icon from "../../../../components/shared/icon/Icon";

const initialFilterData = {
  date: {
    type: RangeType.CurrentYear,
    range: getRangeCurrentYear(),
  },
  period: PeriodType.Month,
  groups: [],
};

const RevenueInDetail = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  let { groupId, accountId } = useParams();

  const [isShowClearAll, setIsShowClearAll] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState(
    initialFilterData.date
  );

  const [periodType, setPeriodType] = useState(initialFilterData.period);

  const [chartType] = useState<ChartType>(ChartType.GROUP);

  const revenue = useAppSelector((state) => state.dashboard.chartData);

  const loading = useAppSelector((state) => state.dashboard.chartLoading);

  const totalSum = useMemo(() => {
    return formatNumber(sumRevenueExpenses(revenue.data));
  }, [revenue.data]);

  useEffect(() => {
    const promises = [
      dispatch(getCompanyBusinessLine()),
      dispatch(getCompanyBranch()),
    ];
    Promise.all(promises).finally(() => {});
  }, [dispatch]);

  useEffect(() => {
    if (!accountId) return;
    dispatch(
      getRevenueInDetail({
        periodType,
        fromDate: moment(selectedDateRange.range[0]).format("YYYY-MM-DD"),
        toDate: moment(selectedDateRange.range[1]).format("YYYY-MM-DD"),
        chartType: ChartType.GROUP,
        accountId,
      })
    );
  }, [dispatch, periodType, chartType, accountId, selectedDateRange]);

  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <Title>{t("RevenueInDetail.Title")}</Title>
          <NavLink>
            <Link to={`/app/Revenue/${groupId}`}>
              <Icon iconName="leftArrow" height={12} width={7} />
              <span> {t("RevenueInDetail.SubTitle")}</span>
            </Link>
          </NavLink>
        </TitleWrapper>
        <Filters
          isShowClearAll={isShowClearAll}
          selectedDateRange={selectedDateRange}
          handleDateRange={(dateData) => {
            setIsShowClearAll(true);
            setSelectedDateRange(dateData);
          }}
          handleClearAll={() => {
            setIsShowClearAll(false);
            setSelectedDateRange(initialFilterData.date);
          }}
        />
      </Header>
      {loading.groups === "pending" ? (
        <SkeletonWrapper>
          <BigCardSkeleton />
          <CardGroupsSkeleton />
        </SkeletonWrapper>
      ) : (
        <>
          <DashboardChartCard
            selectedPeriodType={periodType}
            selectedChartType={chartType}
            selectedViewType={ChartMode.Tree}
            type={CardTypes.LARGE}
            title={t("RevenueInDetail.DashboardChartCardTitle")}
            value={totalSum}
            handlePeriodType={setPeriodType}
          >
            <TreeChartContainer>
              <TreeChart data={revenue.data} />
            </TreeChartContainer>
          </DashboardChartCard>
          <GroupDetailChartWrapper>
            <BarChartTitleContainer>
              <Title>{t("RevenueInDetail.BarChartTitle")}</Title>
            </BarChartTitleContainer>
            <TableChart periodType={periodType} data={revenue.data} />
          </GroupDetailChartWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default RevenueInDetail;
