import moment from "moment";
import { PeriodType } from "../enums/enum";
import {
  CashFlow,
  CashFlowCategoryType,
  CashFlowDirectionType,
  FinancialRecord,
  ProfitLostData,
} from "../store/dashboard/dashboard.types";
import { formatDateByPeriod } from "./helper";

export const sumOfGroup = (array: string[]): number => {
  return array.reduce((sum, value) => sum + +parseFloat(value).toFixed(0), 0);
};

export const sumRevenueExpenses = (array: Array<FinancialRecord>): number => {
  return Number(
    array.reduce((sum, item) => sum + item.sumAmount, 0).toFixed(1)
  );
};

export const sumRevenueExpensesWithName = (
  array: Array<FinancialRecord>,
  groupName: string
): number => {
  return Number(
    array
      .reduce(
        (sum, item) => (item.name === groupName ? sum + item.sumAmount : sum),
        0
      )
      .toFixed(1)
  );
};

type OutputData = {
  period: string;
} & {
  [key: string]: number | string;
};

export const generateOrderedStackChartData = (
  data: Array<FinancialRecord>,
  periodType: PeriodType
): OutputData[] => {
  const grouped = data.reduce<Record<string, OutputData>>((acc, item) => {
    const { period, name, sumAmount } = item;
    const formatPeriod = formatDateByPeriod(period, periodType);
    acc[formatPeriod] = acc[formatPeriod] || { period: formatPeriod };
    acc[formatPeriod][name] = sumAmount;
    return acc;
  }, {});

  const orderedGroupNames = Array.from(
    new Set(data.map((item) => item.name))
  ).sort(
    (a, b) =>
      data.find((item) => item.name === a)!.ordering -
      data.find((item) => item.name === b)!.ordering
  );

  return Object.values(grouped).map((entry) => {
    const orderedEntry: OutputData = { period: entry.period };
    orderedGroupNames.forEach((groupName) => {
      if (entry[groupName] !== undefined) {
        orderedEntry[groupName] = entry[groupName];
      }
    });
    return orderedEntry;
  });
};

export const getTableLabels = (
  data: Array<FinancialRecord>
): Array<{ id: number; name: string }> => {
  return Array.from(
    new Map(data.map((item) => [item.name, [item.id, item.ordering]])).entries()
  )
    .sort((a, b) => Number(a[1][1]) - Number(b[1][1]))
    .map(([name, data]) => ({
      name,
      id: +data[0],
    }));
};

export const getUniqueLabels = (data: Array<FinancialRecord>): string[] => {
  return Array.from(
    new Map(data.map((item) => [item.name, item.ordering])).entries()
  )
    .sort((a, b) => a[1] - b[1])
    .map(([name]) => name);
};

export const getUniquePeriods = (data: Array<FinancialRecord>): string[] => {
  return Array.from(new Set(data.map((item) => item.period)));
};

export const getTreeChartData = (
  data: Array<FinancialRecord>,
  colors: Array<string>
): Array<{ name: string; value: number; color: string }> => {
  const totalSum = data.reduce((total, item) => total + item.sumAmount, 0);

  // Summarize data by mappingGroupName with their total sum
  const summary = data.reduce<
    Record<string, { value: number; ordering: number }>
  >((acc, { name, sumAmount, ordering }) => {
    if (!acc[name]) {
      acc[name] = {
        value: 0,
        ordering: ordering,
      };
    }
    acc[name].value += sumAmount;
    return acc;
  }, {});

  // Convert to array, sort by ordering, and map to final output
  return Object.entries(summary)
    .sort(([, a], [, b]) => a.ordering - b.ordering) // Sort by accountMappingGroupOrdering
    .map(([name, { value }], index) => {
      const percentage = totalSum > 0 ? (value / totalSum) * 100 : 0;
      return {
        name,
        value: parseFloat(percentage.toFixed(2)) || 0,
        color: colors[index % colors.length], // Handle cases where colors array is shorter
      };
    });
};

export const generateTableData = (
  labels: Array<string>,
  data: Array<FinancialRecord>
): Array<Array<string>> => {
  let tableData: Array<Array<string>> = [];
  labels.forEach((label) => {
    let itemData: Array<string> = [];
    data.forEach((dataItem) => {
      if (dataItem.name === label) {
        itemData.push(dataItem.sumAmount.toFixed(1));
      }
    });
    tableData.push(itemData);
  });

  return tableData;
};

export type GridDataItem = {
  id: number | string;
  title: string;
  data: { [key: string]: number | string; period: string }[];
};

export function generateGridData(
  data: Array<FinancialRecord>,
  periodType: PeriodType
): GridDataItem[] {
  const groupedData: Record<
    string,
    { [key: string]: number | string; period: string; id: number | string }[]
  > = {};
  const sortedData = [...data].sort((a, b) => a.ordering - b.ordering);

  sortedData.forEach((item) => {
    const { id, name, sumAmount, period } = item;
    if (!groupedData[name]) {
      groupedData[name] = [];
    }
    groupedData[name].push({
      [name]: sumAmount,
      period: formatDateByPeriod(period, +periodType),
      id,
    });
  });

  return Object.entries(groupedData).map(([title, data]) => ({
    title,
    data,
    id: data[0]?.id || 0,
  }));
}

// old version
export interface GroupedChartDataRecord {
  [date: string]: {
    label: string;
    [key: string]: number | string;
  };
}

export interface BarChartDataRecord {
  data: Array<{ label: string; [key: string]: number | string }>;
  labels: Array<string>;
}
export const generateProfitLoseGroupedChartDataForGroups = (
  data: Array<ProfitLostData>,
  periodType: PeriodType
) => {
  let GroupedBarData: GroupedChartDataRecord = {};

  data.forEach((item) => {
    if (!GroupedBarData[item.period]) {
      GroupedBarData[item.period] = {
        label: formatDateByPeriod(item.period, +periodType),
        ...item,
      };
    }
  });

  return GroupedBarData;
};

// CashFlow
export const generateCashFlowGroupedChartDataForGroups = (
  data: Array<CashFlow>,
  periodType: PeriodType
) => {
  const groupedData: { [key: string]: any } = {};

  data.forEach((cashFlowItem) => {
    const { period, cashFlowCategory, cashFlowDirection, amount } =
      cashFlowItem;
    if (!groupedData[formatDateByPeriod(period, periodType)])
      groupedData[formatDateByPeriod(period, periodType)] = {
        period: formatDateByPeriod(period, periodType),
      };

    const directionKey =
      cashFlowDirection === CashFlowDirectionType.Received
        ? "Received"
        : "Paid";
    const categoryKey = CashFlowCategoryType[cashFlowCategory];

    groupedData[formatDateByPeriod(period, periodType)][
      `${directionKey}-${categoryKey}`
    ] = amount;
  });
  return Object.values(groupedData);
};

// cashFlow
export const generateCashFlowOutcome = (
  keys: Array<string>,
  data: Array<CashFlow>,
  periodType: PeriodType
) => {
  let netIncome: BarChartDataRecord = { data: [], labels: [] };
  keys.forEach((key: string) => {
    let barData: any = {};
    data.forEach((item) => {
      if (!barData[item.period]) {
        barData[item.period] = {
          label: formatDateByPeriod(item.period, +periodType),
          ["Net Cash Flow"]:
            item.cashFlowDirection === CashFlowDirectionType.Received
              ? item.amount
              : -item.amount,
        };
      } else {
        barData[item.period]["Net Cash Flow"] =
          item.cashFlowDirection === CashFlowDirectionType.Received
            ? barData[item.period]["Net Cash Flow"] + item.amount
            : barData[item.period]["Net Cash Flow"] - item.amount;
      }
    });
    netIncome = { data: Object.values(barData), labels: [key] };
  });

  return netIncome;
};

export const generateProfitLossNetIncome = (
  keys: Array<string>,
  data: Array<ProfitLostData>,
  periodType: PeriodType
) => {
  let netIncome: BarChartDataRecord = { data: [], labels: [] };
  keys.forEach((key: string) => {
    let barData: any = {};
    data.forEach((item) => {
      if (!barData[item.period]) {
        barData[item.period] = {
          label: formatDateByPeriod(item.period, +periodType),
          Income: (item["Total Income"] || 0) - (item["Total Expense"] || 0),
        };
      }
    });
    netIncome = { data: Object.values(barData), labels: [key] };
  });

  return netIncome;
};

export const generateProfitLossNetIncomePercentage = (
  keys: Array<string>,
  data: Array<ProfitLostData>,
  periodType: PeriodType
) => {
  let netIncome: BarChartDataRecord = { data: [], labels: [] };
  keys.forEach((key: string) => {
    let barData: any = {};
    data.forEach((item) => {
      if (!barData[item.period]) {
        barData[item.period] = {
          label: formatDateByPeriod(item.period, +periodType),
          Income:
            ((item["Total Income"] - item["Total Expense"]) /
              item["Total Income"]) *
              100 && item["Total Income"]
              ? ((item["Total Income"] - item["Total Expense"]) /
                  item["Total Income"]) *
                100
              : 0,
        };
      }
    });
    netIncome = { data: Object.values(barData), labels: [key] };
  });

  return netIncome;
};

export const formatNumber = (sum: number) =>
  sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getMonthlyPeriods = (
  fromDate: string,
  toDate: string
): string[] => {
  const start = new Date(fromDate);
  const end = new Date(toDate);
  const result: string[] = [];

  const current = new Date(start.getFullYear(), start.getMonth(), 1);

  while (current <= end) {
    const month = current.getMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : month.toString();
    result.push(`${current.getFullYear()}-${formattedMonth}`);
    current.setMonth(current.getMonth() + 1);
  }

  return result;
};

// todo
export const getRangPeriodsByType = (
  startDate: string,
  endDate: string,
  periodType: PeriodType
): string[] => {
  const periods: string[] = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    periods.push(formatDateByPeriod(moment(currentDate).format(), periodType));

    switch (periodType) {
      case PeriodType.Day:
        currentDate.setDate(currentDate.getDate() + 1);
        break;
      case PeriodType.Week:
        currentDate.setDate(currentDate.getDate() + 7);
        break;
      case PeriodType.Month:
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case PeriodType.Quarter:
        currentDate.setMonth(currentDate.getMonth() + 3);
        break;
      case PeriodType.SemiAnnual:
        currentDate.setMonth(currentDate.getMonth() + 6);
        break;
      case PeriodType.Annual:
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
      default:
        throw new Error("Invalid period type");
    }
  }

  return periods;
};
