import { Treemap, ResponsiveContainer, Tooltip } from "recharts";

import { chartColors } from "../../../../constants/color";
import { getTreeChartData } from "../../../../helpers/dashboardHelper";
import { FinancialRecord } from "../../../../store/dashboard/dashboard.types";

const CustomContent = ({ x, y, width, height, name, value, color }: any) => {
  const gap = 4;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{ fill: "#fff" }}
      />
      <rect
        x={x + gap / 2}
        y={y + gap / 2}
        rx={6}
        ry={6}
        width={width - gap}
        height={height - gap}
        style={{ fill: color, stroke: "#000", strokeWidth: 0 }}
      />
      {name && width > 50 && height > 40 && (
        <>
          <text
            x={x + 5}
            y={y + 25}
            fontSize="14"
            fontWeight="300"
            fill="#000"
            width={width - gap}
          >
            {name}
          </text>
          <text
            x={x + 5}
            y={y + 45} // Adjust y if text wraps
            fontWeight="300"
            fontSize="14"
            fill="#000"
          >
            {value}%
          </text>
        </>
      )}
    </g>
  );
};

interface IProps {
  data: Array<FinancialRecord>;
}

const TreeChart = ({ data }: IProps) => {
  const treeData = getTreeChartData(data, chartColors);

  return (
    <ResponsiveContainer width={"100%"} height={420}>
      <Treemap
        width={400}
        height={200}
        animationDuration={600}
        animationEasing="ease-out"
        data={treeData}
        dataKey="value"
        fill="#8884d8"
        content={<CustomContent />}
      >
        {/* <Tooltip
          wrapperStyle={{ zIndex: 10 }}
          // content={<CustomTooltip />}
          // position={{ y: 40 }}
        /> */}
      </Treemap>
    </ResponsiveContainer>
  );
};

export default TreeChart;
