import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BarChartTitleContainer,
  GroupBarChartContainer,
  GroupDetailChartWrapper,
  Header,
  NavLink,
  SkeletonWrapper,
  Title,
  TitleWrapper,
  TreeChartContainer,
  Wrapper,
} from "./ExpensesByGroupId.style";

import moment from "moment";
import BigCardSkeleton from "../../../../components/shared/dashboard/skeletons/bigCardSkeleton/BigCardSkeleton";
import { generateGridChartModes } from "../../../../helpers/helper";
import Filters from "../../../../components/shared/dashboard/filters/Filters";
import CardGroupsSkeleton from "../../../../components/shared/dashboard/skeletons/cardGroupsSkeleton/CardGroupsSkeleton";
import DashboardChartCard from "../../../../components/shared/card/Card";

import { useAppDispatch, useAppSelector } from "../../../../store";
import { getExpensesGroupById } from "../../../../store/dashboard/dashboardAction";
import {
  getCompanyBranch,
  getCompanyBusinessLine,
} from "../../../../store/company/companyAction";
import {
  CardTypes,
  ChartMode,
  ChartType,
  PeriodType,
  RangeType,
} from "../../../../enums/enum";
import { getRangeCurrentYear } from "../../../../helpers/dateHelper";
import { FinancialGroupByIdRequest } from "../../../../store/dashboard/dashboard.types";
import GroupBarChart from "../../../../components/desktop/charts/groupBar/GroupBarChart";
import TreeChart from "../../../../components/desktop/charts/treeChart/TreeChart";
import BarChartLabels from "../../../../components/shared/dashboard/barChartLabels/BarChartLabels";

import TableChart from "../../../../components/desktop/charts/tableChart/TableChart";
import ChartTypeSwitcher from "../../../../components/shared/dashboard/chartTypeSwitcher/ChartTypeSwitcher";
import GridChart from "../../../../components/desktop/charts/gridChart/GridChart";
import {
  formatNumber,
  sumRevenueExpenses,
} from "../../../../helpers/dashboardHelper";
import { PageURL } from "../../../../constants/router";
import { Link, useNavigate, useParams } from "react-router-dom";
import Icon from "../../../../components/shared/icon/Icon";

const initialFilterData = {
  date: {
    type: RangeType.CurrentYear,
    range: getRangeCurrentYear(),
  },
  period: PeriodType.Month,
  groups: [],
  branches: [],
  businessLines: [],
};

const ExpensesByGroupId = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { groupId } = useParams();

  const [isShowClearAll, setIsShowClearAll] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState(
    initialFilterData.date
  );

  const [selectedPeriodType, setSelectedPeriodType] = useState(
    initialFilterData.period
  );
  const [selectedGroups, setSelectedGroups] = useState<Array<string>>(
    initialFilterData.groups
  );
  const [selectedBranches, setSelectedBranches] = useState<Array<string>>(
    initialFilterData.branches
  );
  const [selectedBusinessLine, setSelectedBusinessLine] = useState<
    Array<string>
  >(initialFilterData.businessLines);

  const [cardViewMode, setCardViewMode] = useState<ChartMode>(
    ChartMode.Stacked
  );
  const [viewMode, setViewMode] = useState<ChartMode>(ChartMode.Grid);
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(
    ChartType.GROUP
  );

  const companyBusinessLine = useAppSelector(
    (state) => state.company.companyBusinessLine
  ).map((item) => ({ id: item.id.toString(), name: item.name }));

  const companyBranches = useAppSelector(
    (state) => state.company.companyBranch
  ).map((item) => ({ id: item.id.toString(), name: item.name }));

  const expenses = useAppSelector((state) => state.dashboard.chartData);

  const expensesGroupsOption = useAppSelector(
    (state) => state.dashboard.groupsOption
  );

  const loading = useAppSelector((state) => state.dashboard.chartLoading);

  const totalSum = useMemo(() => {
    return formatNumber(sumRevenueExpenses(expenses.data));
  }, [expenses.data]);

  useEffect(() => {
    const promises = [
      dispatch(getCompanyBusinessLine()),
      dispatch(getCompanyBranch()),
    ];
    Promise.all(promises).finally(() => {});
  }, [dispatch]);

  useEffect(() => {
    if (!groupId) return;

    dispatch(
      getExpensesGroupById({
        periodType: PeriodType.Month,
        fromDate: moment(initialFilterData.date.range[0]).format("YYYY-MM-DD"),
        toDate: moment(initialFilterData.date.range[1]).format("YYYY-MM-DD"),
        chartType: ChartType.GROUP,
        accountMappingGroupId: +groupId,
      })
    );
  }, [dispatch, groupId]);

  const handleClearAll = () => {
    if (!groupId) return;
    dispatch(
      getExpensesGroupById({
        periodType: selectedPeriodType,
        fromDate: moment(initialFilterData.date.range[0]).format("YYYY-MM-DD"),
        toDate: moment(initialFilterData.date.range[1]).format("YYYY-MM-DD"),
        chartType: selectedChartType,
        accountMappingGroupId: +groupId,
      })
    );
  };

  const handleFilter = ({
    businessLines,
    branches,
    groups,
    periodType,
    chartType,
    dateRange,
  }: {
    businessLines?: Array<string>;
    branches?: Array<string>;
    groups?: Array<string>;
    periodType?: PeriodType;
    chartType?: ChartType;
    dateRange?: Array<Date>;
  }) => {
    if (!groupId) return;
    let data: FinancialGroupByIdRequest = {
      periodType: periodType ? periodType : selectedPeriodType,
      fromDate: dateRange?.length
        ? moment(dateRange[0]).format("YYYY-MM-DD")
        : moment(selectedDateRange.range[0]).format("YYYY-MM-DD"),
      toDate: dateRange?.length
        ? moment(dateRange[1]).format("YYYY-MM-DD")
        : moment(selectedDateRange.range[1]).format("YYYY-MM-DD"),
      chartType: chartType ? chartType : selectedChartType,
      accountMappingGroupId: +groupId,
    };

    if (businessLines?.length || selectedBusinessLine.length) {
      data = {
        ...data,
        companyBusinessLineIds: businessLines?.length
          ? businessLines
          : selectedBusinessLine,
      };
    }
    if (branches?.length || selectedBranches.length) {
      data = {
        ...data,
        companyBranchIds: branches?.length ? branches : selectedBranches,
      };
    }
    if (groups?.length || selectedGroups) {
      data = {
        ...data,
        accountIds: groups?.length ? groups : selectedGroups,
      };
    }

    dispatch(getExpensesGroupById(data));
  };

  const handleNavigationGroupDetail = (accountId: number | string) => {
    navigate(accountId.toString());
  };

  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <Title>{t("ExpensesByGroupId.Title")}</Title>
          <NavLink>
            <Link to={PageURL.Expenses.path}>
              <Icon iconName="leftArrow" height={12} width={7} />
              <span>{t("ExpensesByGroupId.SubTitle")}</span>
            </Link>
          </NavLink>
        </TitleWrapper>
        <Filters
          isShowClearAll={isShowClearAll}
          groupOptions={expensesGroupsOption}
          selectedDateRange={selectedDateRange}
          selectedBranches={selectedBranches}
          selectedGroups={selectedGroups}
          selectedBusinessLine={selectedBusinessLine}
          branchOptions={companyBranches}
          lineOptions={companyBusinessLine}
          handleDateRange={(dateData) => {
            setIsShowClearAll(true);
            setSelectedDateRange(dateData);
            handleFilter({ dateRange: dateData.range });
          }}
          handleBusinessLines={(businessLines) => {
            setSelectedBusinessLine(businessLines);
            setIsShowClearAll(true);
            handleFilter({ businessLines });
          }}
          handleBranches={(branches) => {
            setSelectedBranches(branches);
            setIsShowClearAll(true);
            handleFilter({ branches });
          }}
          handleGroups={(groups) => {
            setSelectedGroups(groups);
            setIsShowClearAll(true);
            handleFilter({ groups });
          }}
          handleClearAll={() => {
            setIsShowClearAll(false);
            setSelectedDateRange(initialFilterData.date);
            setSelectedBusinessLine(initialFilterData.businessLines);
            setSelectedBranches(initialFilterData.branches);
            setSelectedGroups(initialFilterData.groups);
            handleClearAll();
          }}
        />
      </Header>
      {loading.groups === "pending" ? (
        <SkeletonWrapper>
          <BigCardSkeleton />
          <CardGroupsSkeleton />
        </SkeletonWrapper>
      ) : (
        <>
          <DashboardChartCard
            selectedPeriodType={selectedPeriodType}
            selectedChartType={selectedChartType}
            selectedViewType={cardViewMode}
            type={CardTypes.LARGE}
            title={t("ExpensesByGroupId.DashboardCardTitle")}
            value={totalSum}
            hasBranch={Boolean(companyBranches.length)}
            hasLine={Boolean(companyBusinessLine.length)}
            handlePeriodType={(periodType) => {
              setSelectedPeriodType(periodType);
              handleFilter({ periodType });
            }}
            handleChartType={(chartType) => {
              setSelectedChartType(chartType);
              handleFilter({ chartType });
            }}
            handleViewMode={setCardViewMode}
          >
            {cardViewMode === ChartMode.Stacked && (
              <GroupBarChartContainer>
                <BarChartLabels data={expenses.data} />
                <GroupBarChart
                  data={expenses.data}
                  periodType={selectedPeriodType}
                  stackId={"a"}
                />
              </GroupBarChartContainer>
            )}
            {cardViewMode === ChartMode.Tree && (
              <TreeChartContainer>
                <TreeChart data={expenses.data} />
              </TreeChartContainer>
            )}
          </DashboardChartCard>
          <GroupDetailChartWrapper>
            <BarChartTitleContainer>
              <Title>{t("ExpensesByGroupId.BarChartTitle")}</Title>
              <ChartTypeSwitcher
                viewOptions={generateGridChartModes()}
                selectedViewMode={viewMode}
                handleViewMode={setViewMode}
              />
            </BarChartTitleContainer>
            {viewMode === ChartMode.Grid && (
              <GridChart
                handleViewDetail={
                  selectedChartType === ChartType.GROUP
                    ? handleNavigationGroupDetail
                    : undefined
                }
                periodType={selectedPeriodType}
                data={expenses.data}
              />
            )}
            {viewMode === ChartMode.Table && (
              <TableChart
                handleClick={
                  selectedChartType === ChartType.GROUP
                    ? handleNavigationGroupDetail
                    : undefined
                }
                periodType={selectedPeriodType}
                data={expenses.data}
              />
            )}
          </GroupDetailChartWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default ExpensesByGroupId;
