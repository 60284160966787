export enum ExcelFileStatus {
  Active = 1,
  Error,
  Uploaded,
  PartiallyUploaded,
}

export enum ExcelFileType {
  Transaction = 1,
  Clients = 2,
  Account = 3,
}

export enum UserStatus {
  Active = 1,
  Deactivated,
}

export enum PeriodType {
  Day = 1,
  Week = 2,
  Month = 3,
  Quarter = 4,
  Annual = 5,
  SemiAnnual = 6,
}

export enum BottomSheetType {
  IDLE = "IDLE",
  PERSONAL_INFO = "PERSONAL_INFO",
  NAVIGATION = "NAVIGATION",
  ADMINISTRATION = "ADMINISTRATION",
  SWITCH_ACCOUNT = "SWITCH_ACCOUNT",
  FILTER = "FILTER",
  CHART_VIEW_TYPE = "CHART_VIEW_TYPE",
  DATE_RANGE = "DATE_RANGE",
  DATE_TYPE = "DATE_TYPE",
  BUSINESS_LINE = "BUSINESS_LINE",
  BRANCH = "BRANCH",
  GROUP_NAME = "GROUP_NAME",
}

export enum RangeType {
  Custom = 0,
  LastDays30 = 1,
  LastDays90 = 2,
  LastDays120 = 3,
  CurrentMonth = 4,
  CurrentQuarter = 5,
  CurrentYear = 6,
  PrevMonth = 7,
  PrevQuarter = 8,
  PrevYear = 9,
  PastTwoYear = 10,
  PastThreeYear = 11,
  PastFourYear = 12,
}

export enum ChartGroupTypes {
  Grouped = 1,
  Detailed = 2,
}

export enum Languages {
  En = "en",
  Ge = "ge",
}

export enum ChartMode {
  Stacked = "Stacked",
  Tree = "Tree",
  Table = "Table",
  Grid = "Grid",
}

export enum ChartType {
  GROUP = 1,
  BRANCH = 2,
  BUSINESS_LINE = 3,
}

export enum CardTypes {
  LARGE = "LARGE",
  SMALL = "SMALL",
}
