import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en.json";
import ge from "./ge.json";

/* eslint-disable @typescript-eslint/no-floating-promises */
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: en,
      },
      ge: {
        translation: ge,
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import HttpApi from "i18next-http-backend"; // Import the HTTP backend

// const availableLanguages = {
//   en: 1,
//   ge: 2,
// };

// /* eslint-disable @typescript-eslint/no-floating-promises */
// i18n
//   .use(initReactI18next)
//   .use(LanguageDetector)
//   .use(HttpApi) // Use the HTTP backend for loading translations
//   .init({
//     fallbackLng: "en",
//     interpolation: {
//       escapeValue: false,
//     },
//     backend: {
//       // Configure the backend settings
//       loadPath: (lng: Array<keyof typeof availableLanguages>) => {
//         const backendUrl =
//           process.env.REACT_APP_URL || "https://dev-api.amadeo.ge";
//         const languageCode = availableLanguages[lng[0]] || 1;
//         return `${backendUrl}/Localization/UIComponentTranslation/GetTranslations?languageId=${languageCode}`;
//       },
//       parse: (data: any) => {
//         const parsedData = JSON.parse(data); // Parse the JSON response
//         return parsedData.data; // Return the 'data' property that contains the translations
//       },
//     },
//     react: {
//       useSuspense: false, // Optional, depends on your setup
//     },
//   });

// export default i18n;
