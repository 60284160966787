import { PageType } from "../constants/router";
import {
  ChartMode,
  ChartType,
  ExcelFileType,
  PeriodType,
  RangeType,
  UserStatus,
} from "../enums/enum";
import { userPermissions } from "../store/user/user.types";
import moment from "moment";
import "moment/locale/en-gb"; // English (UK) locale
import "moment/locale/ka"; // Georgian locale
import { IconNameOptions } from "../components/shared/icon/Icon";
import {
  getRangeCurrentMonth,
  getRangeCurrentQuarter,
  getRangeCurrentYear,
  getRangeLastDays,
  getRangePreviousMonth,
  getRangePreviousQuarter,
  getRangePreviousYearWithParameter,
} from "./dateHelper";

export const isMobileDevice = () => {
  const ua = navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod|android|mobile/i.test(ua);
};

export const downloadFile = async (fileUrl: string) => {
  try {
    const response = await fetch(fileUrl, {
      mode: "cors", // Ensure CORS requests are handled if cross-origin
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const blob = await response.blob();
    if (!blob.size) {
      throw new Error("Blob is empty");
    }
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = "downloaded_file";
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};

export const uploadFile = (file: File, url: string) => {
  return fetch(url, {
    method: "PUT", // Use the PUT method
    body: file, // Attach the file as the body of the request
    headers: {
      "Content-Type": file.type,
    },
  });
};

export const generateFileYearsData = (): Array<{
  label: string;
  value: string;
}> => {
  const currentYear = new Date().getFullYear();
  const yearObjects = [];

  for (let year = 2000; year <= currentYear; year++) {
    yearObjects.push({ label: year.toString(), value: year.toString() });
  }

  return yearObjects.reverse();
};

export const generatePreviousYearsData = (
  year: number
): Array<{
  label: string;
  value: string;
}> => {
  const currentYear = new Date().getFullYear() - year;
  const yearObjects = [];

  for (let year = 2000; year <= currentYear; year++) {
    yearObjects.push({ label: year.toString(), value: year.toString() });
  }

  return yearObjects.reverse();
};
export const generateCategoryFilterData = (): Array<{
  label: string;
  value: string;
}> => {
  return [
    { value: "", label: "CategoryFilter.All" },
    {
      value: ExcelFileType.Account.toString(),
      label: "CategoryFilter.Account",
    },
    {
      value: ExcelFileType.Clients.toString(),
      label: "CategoryFilter.Clients",
    },
    {
      value: ExcelFileType.Transaction.toString(),
      label: "CategoryFilter.Transaction",
    },
  ];
};

export const generateUserStatuses = (
  forFilter: boolean = true
): Array<{
  label: string;
  value: string;
}> => {
  if (forFilter) {
    return [
      { value: "", label: "UserStatus.All" },
      { value: UserStatus.Active.toString(), label: "UserStatus.Active" },
      {
        value: UserStatus.Deactivated.toString(),
        label: "UserStatus.Deactivated",
      },
    ];
  }
  return [
    { value: UserStatus.Active.toString(), label: "UserStatus.Active" },
    {
      value: UserStatus.Deactivated.toString(),
      label: "UserStatus.Deactivated",
    },
  ];
};

export const generatePeriodTypes = (): Array<{
  label: string;
  value: string;
}> => {
  return [
    { value: PeriodType.Day.toString(), label: "PeriodTypes.Day" },
    { value: PeriodType.Week.toString(), label: "PeriodTypes.Week" },
    { value: PeriodType.Month.toString(), label: "PeriodTypes.Month" },
    { value: PeriodType.Quarter.toString(), label: "PeriodTypes.Quarter" },
    {
      value: PeriodType.SemiAnnual.toString(),
      label: "PeriodTypes.SemiAnnual",
    },
    { value: PeriodType.Annual.toString(), label: "PeriodTypes.Annual" },
  ];
};

export const generateCardChartModes = (): Array<{
  mode: ChartMode;
  label: string;
  icon: IconNameOptions;
}> => {
  return [
    {
      mode: ChartMode.Stacked,
      label: "ChartMode.Stacked",
      icon: "stackedChart",
    },
    {
      mode: ChartMode.Tree,
      label: "ChartMode.Tree",
      icon: "treeChart",
    },
  ];
};

export const generateGridChartModes = (): Array<{
  mode: ChartMode;
  label: string;
  icon: IconNameOptions;
}> => {
  return [
    {
      mode: ChartMode.Grid,
      label: "ChartMode.Grid",
      icon: "grid",
    },
    {
      mode: ChartMode.Table,
      label: "ChartMode.Table",
      icon: "tableView",
    },
  ];
};

export const generateGridChartTypes = (
  hasLine: boolean,
  hasBranch: boolean
): Array<{
  value: string;
  label: string;
}> => {
  const chartTypes = [
    { value: ChartType.GROUP.toString(), label: "ChartType.Group" },
    hasBranch && {
      value: ChartType.BRANCH.toString(),
      label: "ChartType.Branch",
    },
    hasLine && {
      value: ChartType.BUSINESS_LINE.toString(),
      label: "ChartType.BusinessLine",
    },
  ].filter(Boolean) as Array<{ value: string; label: string }>;

  return chartTypes.length > 0 ? chartTypes : [];
};
export const checkPermissionForRoute = (
  pathname: string,
  pages: PageType,
  permissions: userPermissions
) => {
  const pageKey = Object.keys(pages).find((key) => {
    const pagePath = pages[key as keyof PageType].path;

    return pathname === `/app/${pagePath}` || pathname === pagePath;
  }) as keyof PageType | undefined;

  if (pageKey) {
    const requiredPermission = pages[pageKey]?.permissionKey;

    return requiredPermission ? !!permissions[requiredPermission] : true;
  }

  return pathname === "/app";
};

export const formatDate = (time: string, format?: string) => {
  return moment(time).format(format || "DD MMM YYYY");
};

export const getDateInfo = (time: string) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatDateByPeriod = (time: string, period: PeriodType) => {
  switch (period) {
    case PeriodType.Day: {
      return moment(time).format("MM/DD");
    }
    case PeriodType.Week: {
      return moment(time).format("MM/DD");
    }
    case PeriodType.Month: {
      return moment(time).format("YY/MM");
    }
    case PeriodType.Quarter: {
      const year = moment(time).year();
      const quarter = moment(time).quarter();
      return `${year}-Q${quarter}`;
    }
    case PeriodType.SemiAnnual: {
      const year = moment(time).format("YY");
      const month = moment(time).month();
      const half = month < 6 ? "H1" : "H2";
      return `${year}/${half}`;
    }
    case PeriodType.Annual: {
      return moment(time).format("YYYY");
    }
    default:
      throw new Error("Invalid PeriodType");
  }
};
export const getDateRange = (rangeType: RangeType) => {
  const now = new Date();
  let fromDate, toDate;

  switch (rangeType) {
    case RangeType.LastDays30:
      fromDate = new Date(now);
      fromDate.setDate(fromDate.getDate() - 30);
      toDate = now;
      break;
    case RangeType.LastDays90:
      fromDate = new Date(now);
      fromDate.setDate(fromDate.getDate() - 90);
      toDate = now;
      break;
    case RangeType.LastDays120:
      fromDate = new Date(now);
      fromDate.setDate(fromDate.getDate() - 120);
      toDate = now;
      break;
    case RangeType.CurrentMonth:
      fromDate = new Date(now.getFullYear(), now.getMonth(), 1);
      toDate = now;
      break;
    case RangeType.CurrentQuarter:
      const currentQuarter = Math.floor((now.getMonth() + 3) / 3);
      fromDate = new Date(now.getFullYear(), (currentQuarter - 1) * 3, 1);
      toDate = now;
      break;
    case RangeType.CurrentYear:
      fromDate = new Date(now.getFullYear(), 0, 1);
      toDate = now;
      break;
    case RangeType.PrevMonth:
      fromDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      toDate = new Date(now.getFullYear(), now.getMonth(), 0);
      break;
    case RangeType.PrevQuarter:
      const prevQuarter = Math.floor((now.getMonth() + 3) / 3) - 1;
      fromDate = new Date(now.getFullYear(), (prevQuarter - 1) * 3, 1);
      toDate = new Date(now.getFullYear(), prevQuarter * 3, 0);
      break;
    case RangeType.PrevYear:
      fromDate = new Date(now.getFullYear() - 1, 0, 1);
      toDate = new Date(now.getFullYear() - 1, 11, 31);
      break;
    case RangeType.PastTwoYear:
      const pastTwoYear = new Date();
      fromDate = new Date(pastTwoYear.getFullYear() - 1, 0, 1);
      toDate = new Date();
      break;
    case RangeType.PastThreeYear:
      const pastThreeYear = new Date();
      fromDate = new Date(pastThreeYear.getFullYear() - 2, 0, 1);
      toDate = new Date();
      break;
    case RangeType.PastFourYear:
      const pastFourYear = new Date();
      fromDate = new Date(pastFourYear.getFullYear() - 3, 0, 1);
      toDate = new Date();
      break;
    default:
      throw new Error("Invalid RangeType");
  }

  return [fromDate, toDate];
};

export const getTimePeriodText = (periodType: number): string => {
  const periodDict: { [key: number]: string } = {
    1: "Last30Days",
    2: "Last90Days",
    3: "Last120Days",
    4: "CurrentMonth",
    5: "CurrentQuarter",
    6: "CurrentYear",
    7: "PreviousMonth",
    8: "PreviousQuarter",
    9: "PreviousYear",
    10: "PastTwoYear",
    11: "PastThreeYear",
    12: "PastFourYear",
  };

  return periodDict[periodType] || "Invalid period type";
};

export const dashboardDateFormat = (date: string) => {
  return moment(date).format("MMM D, YYYY");
};

export const DateFilterDropdownData = [
  {
    type: RangeType.LastDays120,
    range: getRangeLastDays(120),
    title: "ChartFilter.Last120Days",
  },
  {
    type: RangeType.CurrentMonth,
    range: getRangeCurrentMonth(),
    title: "ChartFilter.CurrentMonth",
  },
  {
    type: RangeType.CurrentQuarter,
    range: getRangeCurrentQuarter(),
    title: "ChartFilter.CurrentQuarter",
  },
  {
    type: RangeType.PrevMonth,
    range: getRangePreviousMonth(),
    title: "ChartFilter.PreviousMonth",
  },
  {
    type: RangeType.PrevQuarter,
    range: getRangePreviousQuarter(),
    title: "ChartFilter.PreviousQuarter",
  },
  {
    type: RangeType.PastThreeYear,
    range: getRangePreviousYearWithParameter(2),
    title: "ChartFilter.PastThreeYear",
  },
  {
    type: RangeType.PastFourYear,
    range: getRangePreviousYearWithParameter(3),
    title: "ChartFilter.PastFourYear",
  },
];
export const DateFilterRangeData = [
  {
    type: RangeType.LastDays30,
    range: getRangeLastDays(30),
    title: "ChartFilter.Last30Days",
  },
  {
    type: RangeType.LastDays90,
    range: getRangeLastDays(90),
    title: "ChartFilter.Last90Days",
  },
  {
    type: RangeType.CurrentYear,
    range: getRangeCurrentYear(),
    title: "ChartFilter.CurrentYear",
  },
  {
    type: RangeType.PastTwoYear,
    range: getRangePreviousYearWithParameter(1),
    title: "ChartFilter.PastTwoYear",
  },
];
