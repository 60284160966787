import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DateRangeWrapper,
  DateRangeCustomItem,
  DateRangeItem,
  Content,
  DropDownWrapper,
  ListItem,
  DropDown,
  ListItemTitle,
  ContentTitle,
  IconWrapper,
  CalendarWrapper,
  ListItemContainer,
} from "./DashboardDateFilter.style";
import Icon from "../../shared/icon/Icon";
import { RangeType } from "../../../enums/enum";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import {
  DateFilterDropdownData,
  DateFilterRangeData,
} from "../../../helpers/helper";

interface IProps {
  selectedDateRange: { type: RangeType; range: Array<Date> };
  handleSelectDate: ({
    type,
    range,
  }: {
    type: RangeType;
    range: Array<Date>;
  }) => void;
}

const DashboardDateFilter = ({
  selectedDateRange,
  handleSelectDate,
}: IProps) => {
  const { i18n, t } = useTranslation();
  const customRef = useRef<HTMLDivElement>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [customWidth, setCustomWidth] = useState(0);

  const [openDropDown, setOpenDropDown] = useState(false);
  const [dateRange, setDateRange] = useState<Array<Date>>([]);

  const formattedDate = useMemo(() => {
    if (selectedDateRange.type === RangeType.Custom && dateRange.length === 2) {
      moment.locale(i18n.language);
      return {
        fromDate: moment(dateRange[0]).format("MMM D, YYYY"),
        toDate: moment(dateRange[1]).format("MMM D, YYYY"),
      };
    }
    return { fromDate: "", toDate: "" };
  }, [i18n.language, dateRange, selectedDateRange.type]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpenDropDown(false);
    }
  };
  // TODO need remove this part
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setCustomWidth(entry.contentRect.width);
      }
    });
    if (customRef.current) {
      observer.observe(customRef.current);
    }
    return () => {
      if (customRef.current) {
        observer.unobserve(customRef.current);
      }
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isSelectedMore = useMemo(() => {
    return (
      selectedDateRange.type === RangeType.LastDays120 ||
      selectedDateRange.type === RangeType.CurrentQuarter ||
      selectedDateRange.type === RangeType.PrevMonth ||
      selectedDateRange.type === RangeType.PrevQuarter ||
      selectedDateRange.type === RangeType.PastThreeYear ||
      selectedDateRange.type === RangeType.PastFourYear
    );
  }, [selectedDateRange.type]);

  return (
    <div>
      <DateRangeWrapper>
        <DateRangeCustomItem
          ref={customRef}
          isSelected={selectedDateRange.type === RangeType.Custom}
          onClick={() => {}}
        >
          <Icon
            fill={
              selectedDateRange.type === RangeType.Custom
                ? "primary"
                : "default"
            }
            iconName="calendar"
            height={16}
            width={16}
          />
          {formattedDate.fromDate && formattedDate.toDate ? (
            <>
              {formattedDate.fromDate} - {formattedDate.toDate}
            </>
          ) : (
            <> {t("DashboardDateFilter.CustomTitle")}</>
          )}
        </DateRangeCustomItem>
        {DateFilterRangeData.map((item, index) => (
          <DateRangeItem
            key={index}
            isSelected={selectedDateRange.type === item.type}
            onClick={() =>
              handleSelectDate({
                type: item.type,
                range: item.range,
              })
            }
          >
            {t(item.title)}
          </DateRangeItem>
        ))}
        <CalendarWrapper calendarWidth={customWidth}>
          <DateRangePicker
            caretAs={null}
            appearance="subtle"
            placeholder="Subtle"
            showHeader={false}
            ranges={[]}
            format="yyyy/MM/dd"
            style={{ width: "100%", cursor: "pointer", height: "100%" }}
            onChange={(data) => {
              if (data) {
                const [fromDate, toDate] = data;
                handleSelectDate({
                  type: RangeType.Custom,
                  range: [fromDate, toDate],
                });
                setDateRange([fromDate, toDate]);
                try {
                } catch (error) {}
              }
            }}
          />
        </CalendarWrapper>

        <DropDown
          isSelected={isSelectedMore}
          ref={dropdownRef}
          onClick={() => setOpenDropDown((prev) => !prev)}
        >
          <Content>
            <ContentTitle isSelected={isSelectedMore}>
              {t("DashboardDateFilter.MoreTitle")}
            </ContentTitle>
            <IconWrapper className={openDropDown ? "rotate" : ""}>
              <Icon
                fill={isSelectedMore ? "primary" : "default"}
                iconName="arrowDown"
                width={16}
                height={16}
              />
            </IconWrapper>
          </Content>
          {openDropDown && (
            <DropDownWrapper>
              {DateFilterDropdownData.map((item, index) => (
                <ListItem
                  key={index}
                  onClick={() => {
                    handleSelectDate({
                      type: item.type,
                      range: item.range,
                    });
                  }}
                >
                  <ListItemContainer>
                    <ListItemTitle>{t(item.title)}</ListItemTitle>
                    {selectedDateRange.type === item.type && (
                      <Icon
                        iconName="checkMark"
                        width={20}
                        height={20}
                        fill="primary"
                      />
                    )}
                  </ListItemContainer>
                </ListItem>
              ))}
            </DropDownWrapper>
          )}
        </DropDown>
      </DateRangeWrapper>
    </div>
  );
};

export default DashboardDateFilter;
